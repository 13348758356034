mat-expansion-panel.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none;

  .mat-expansion-panel-header {
    padding: 0 0.3rem 0;
    @extend .title-1;

    &:hover {
      background: transparent!important;
    }
  }

  .mat-expansion-panel-body {
    padding: 0 1rem;
  }

  .mat-expansion-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: -0.5rem;
    margin-top: 0;

    &::after {
      border-width: 0 1px 1px 0;
      padding: 5px;
    }
  }

  .mat-expansion-panel-header-title {
    padding-left: 1.4rem;
  }

  &.expansion-panel--right {
    .mat-expansion-panel-header {
      flex-direction: row-reverse;
    }
  }

  &:not(.mat-expanded) .mat-expansion-indicator {
    margin-top: -0.5rem;
    margin-bottom: 0;
  }
}
