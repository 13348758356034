*, *::after, *::before {
  box-sizing: border-box;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  appearance: none;
}

:root {
  font-size: 10px;
}

input {
  font-family: var(--ff-main-font);
}

input::placeholder {
  color: inherit;
}

body {
  display: flex;
  flex-direction: column;
  font-family: var(--ff-main-font);
  color: var(--color-text);
  background: var(--color-bg);
  font-size: 1.4rem;
  min-width: 37.5rem;
  min-height: 100vh;
  overflow-y: scroll;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;

  b, strong {
    font-weight: var(--fw-medium);
  }

  i, em {
    font-style: italic;
  }
}

h1 {
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  font-size: 3.6rem;
  line-height: 5.4rem;
}

h2 {
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  font-size: 3.2rem;
  line-height: 4.8rem;
}

h3 {
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  font-size: 2.4rem;
  line-height: 3.6rem;
}

h4 {
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  font-size: 2.2rem;
  line-height: 3.3rem;
  letter-spacing: -0.01rem;
}

.title-1 {
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  font-size: 1.8rem;
  line-height: 2.7rem;
}

.title-2 {
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  font-size: 1.2rem;
  line-height: 1.8rem;
}

.body-1 {
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  font-size: 1.4rem;
  line-height: 2.1rem;
}

.body-2 {
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  font-size: 1rem;
  line-height: 1.5rem;
}

.body-3 {
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  font-size: 0.8rem;
  line-height: 1.2rem;
}

.body-4 {
  font-style: normal;
  font-weight: var(--fw-semi-bold);
  font-size: 0.6rem;
  line-height: 1rem;
}

.body-5 {
  font-style: normal;
  font-weight: var(--fw-normal);
  font-size: 1.4rem;
  line-height: 2.1rem;
}

.body-6 {
  font-style: normal;
  font-weight: var(--fw-normal);
  font-size: 1.2rem;
  line-height: 18px;
}

.body-7 {
  font-style: normal;
  font-weight: var(--fw-normal);
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.02rem;
}

.body-8 {
  font-style: normal;
  font-weight: var(--fw-normal);
  font-size: 0.8rem;
  line-height: 1.2rem;
}

@media print {
  * {
    print-color-adjust: exact;
  }
}

@page {
  size: A4 portrait;
  margin: 1.5cm;
}
