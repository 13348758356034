mat-checkbox.mat-mdc-checkbox {
  overflow: hidden;

  .mdc-checkbox {
    padding: 0;
    width: 1rem;
    height: 1rem;

    .mdc-checkbox__background {
      top: 0;
      left: 0;
      width: 1rem;
      height: 1rem;
    }
  }

  &.mat-primary {
    .mdc-checkbox__background {
      svg {
        color: var(--color-white) !important;
      }
      background: var(--color-blue) !important;
      border-color: var(--color-blue) !important;
    }
  }
}
