@import "variables/scss-variables";

@font-face {
  font-family: $poppins;
  src: url("../assets/fonts/poppins-bold.eot");
  src:
    local("Poppins Bold"),
    local("Poppins-Bold"),
    url("../assets/fonts/poppins-bold.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/poppins-bold.woff") format("woff"),
    url("../assets/fonts/poppins-bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: $poppins;
  src: url("../assets/fonts/poppins-regular.eot");
  src:
    local("Poppins Regular"),
    local("Poppins-Regular"),
    url("../assets/fonts/poppins-regular.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/poppins-regular.woff") format("woff"),
    url("../assets/fonts/poppins-regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $poppins;
  src: url("../assets/fonts/poppins-semibold.eot");
  src:
    local("Poppins SemiBold"),
    local("Poppins-SemiBold"),
    url("../assets/fonts/poppins-semibold.eot?#iefix") format("embedded-opentype"),
    url("../assets/fonts/poppins-semibold.woff") format("woff"),
    url("../assets/fonts/poppins-semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
