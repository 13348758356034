.text-xs {
  font-size: var(--s-3); /* 12px */
  line-height: var(--s-4); /* 16px */
}
.text-base {
  font-size: var(--s-4); /* 16px */
  line-height: var(--s-5); /* 24px */
}
.text-lg {
  font-size: var(--s-4_5); /* 18px */
  line-height: var(--s-7); /* 28px */
}
