.mat-mdc-form-field {
  .mat-mdc-form-field-flex {
    .mat-mdc-select {
      .mat-mdc-select-value-text {
        font-size: 1.2rem;
        font-weight: var(--fw-normal);
      }
    }
  }
}
