@import "../scss-variables";

:root {
  --ff-main-font: #{$main-font-family}, sans-serif;
  --fw-thin: 100;
  --fw-extra-light: 200;
  --fw-light: 300;
  --fw-normal: normal;
  --fw-medium: 500;
  --fw-semi-bold: 600;
  --fw-bold: bold;
  --fw-extra-bold: 800;
  --fw-ultra-bold: 900;
}
