.dialog-backdrop {
  backdrop-filter: blur(2px);
}

.dialog-backdrop + .cdk-global-overlay-wrapper {
  align-items: flex-start !important;
  margin-top: var(--modal-top-margin);

  .cdk-overlay-pane {
    width: 100%;
  }
}

.dialog__info {
  .mdc-dialog__container .dialog {
    .mat-mdc-dialog-title {
      text-align: center;
      margin-top: 6.5rem;
      margin-bottom: 8.7rem;
    }
  }
}

.mdc-dialog.mat-mdc-dialog-container {
  max-height: calc(100vh - var(--modal-top-margin));
}

.mdc-dialog__container {
  .mat-mdc-dialog-surface {
    border-radius: 6px !important;
  }

  .dialog {
    position: relative;
    background: var(--color-dialog-bg);
    overflow-x: hidden;

    button.mat-mdc-button-base {
      &[mat-flat-button] {
        &.mat-primary {
          background: var(--color-blue) !important;
          color: var(--color-white) !important;
        }

        &.mat-accent {
          background: var(--color-white) !important;
          color: var(--color-blue) !important;
          border: 0.025rem solid var(--color-blue) !important;
        }
      }
    }

    &.dialog_confirm {
      .mat-mdc-dialog-title {
        margin-top: 4.5rem;
        margin-bottom: 2.1rem;
      }
    }

    .mat-mdc-dialog-title {
      margin-top: 1rem;

      &::before {
        display: none;
      }
    }

    &__close {
      position: absolute;
      color: var(--color-dialog-header-color);
      top: 0.8rem;
      right: 2rem;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;

      .mat-icon svg,
      .mat-icon {
        width: 3rem;
        height: 3rem;
        overflow: visible;
      }
    }

    &__header {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__body {
      padding: 1.2rem var(--modal-side-padding) 0;
      color: var(--color-dialog-text-color);
    }

    &__title.mat-mdc-dialog-title {
      color: var(--color-dialog-header-color);
      margin-bottom: 2.1rem;
      padding: 0;
      font-weight: var(--fw-semi-bold);
      font-size: 1.8rem;
      line-height: 2.1rem;
    }

    &__text {
      color: var(--color-blue);
      font-size: 1.4rem;
      line-height: 2.1rem;
    }

    &__message {
      color: var(--color-dialog-massage-color);
      font-weight: var(--fw-semi-bold);
      font-size: 1.4rem;
      line-height: 2.1rem;
    }

    &__template {
      margin-bottom: 1.5em;
    }

    &__actions {
      justify-content: center;
      gap: 2rem;
    }

    .modal-actions__button {
      height: 3rem;

      .mdc-button__label {
        font-weight: var(--fw-semi-bold);
        font-size: 1.4rem;
      }
    }

    .mat-mdc-form-field {
      .mat-mdc-form-field-flex .mat-mdc-input-element {
        color: var(--color-dialog-text-color);
      }

      &:not(.mat-form-field-invalid) {
        .mat-mdc-form-field-flex {
          .mdc-notched-outline__leading,
          .mdc-notched-outline__trailing,
          .mdc-notched-outline__notch {
            border-color: var(--color-dialog-text-color) !important;
            outline: none;
            box-shadow: none;
          }

          .mat-mdc-input-element {
            caret-color: var(--color-dialog-text-color);
            color: var(--color-dialog-text-color);

            &::placeholder {
              color: var(--color-dialog-text-color);
            }
          }

          .mdc-floating-label {
            color: var(--color-dialog-text-color);
          }
        }
      }
    }
  }
}

.no-actions {
  .dialog {
    &__body {
      margin-bottom: 0;
    }
  }
}

.cdk-overlay-pane div.mat-mdc-select-panel {
  &.table-select-dialog {
    padding: 0;
    border-radius: 0.6rem !important;
    background: var(--color-dialog-bg);

    .mat-mdc-option.mdc-list-item {
      position: relative;
      min-height: 3rem;
      padding-left: 3rem;

      span.mdc-list-item__primary-text {
        color: var(--color-dialog-text-color) !important;
        font-weight: var(--fw-normal);
        font-size: 1rem;
      }

      /* stylelint-disable */
      &.mdc-list-item--selected {
        ::before {
          content: '';
          mask: url('/assets/img/svg/active.svg') no-repeat 50% 50%;
          mask-size: cover;
          position: absolute;
          width: 1rem;
          height: 0.8rem;
          top: 50%;
          transform: translateY(-50%);
          left: 1rem;
          background-color: var(--color-dialog-text-color);
        }

        mat-pseudo-checkbox {
          display: none;
        }
      }
    }
  }

  &.chart-panel {
    background: #edf1f2;
    box-shadow: none;
    top: -6px;
    position: relative;
    margin-left: 2px;
    margin-right: 2px;

    .mat-mdc-option {
      padding: 0 2rem;
      min-height: 3rem;
    }

    .mdc-list-item__primary-text {
      color: var(--color-blue) !important;
      font-size: 1.2rem;
      font-weight: var(--fw-normal);
    }
  }
}
