/* stylelint-disable */
mat-table.mat-mdc-table.cdk-table {
  background: var(--color-table-bg);
  /*border-radius: 0.6rem;*/
  overflow: hidden;

  .mat-mdc-header-row {
    min-height: 3.2rem;

    .mat-mdc-header-cell {
      color: var(--color-table-header-text);
      background: var(--color-table-header-bg);
      font-weight: var(--fw-semi-bold);
      font-size: 1.2rem;
      line-height: 1.8rem;

      border-bottom: 0.25px solid var(--color-table-border-color);
      border-right: 0.25px solid var(--color-table-border-color);

      > div {
        max-width: 100%;
      }

      .mat-sort-header-arrow {
        color: var(--color-table-header-text);
      }

      &:last-child {
        border-top-right-radius: 0.6rem;
        border-right: 0.25px solid var(--color-table-border-color);
      }

      &:first-child {
        border-top-left-radius: 0.6rem;
        border-left: 0.25px solid var(--color-table-border-color);
      }
    }
  }

  .mat-mdc-row {
    min-height: 6.4rem;

    &:last-child {
      border-bottom-left-radius: 0.6rem;
      border-bottom-right-radius: 0.6rem;
    }

    .mat-mdc-cell {
      color: var(--color-table-text);
      font-weight: var(--fw-normal);
      font-size: 1.2rem;
      line-height: 1.8rem;

      > div {
        max-width: 100%;
      }

      border-bottom: 0.25px solid var(--color-table-border-color);
      border-right: 0.25px solid var(--color-table-border-color);

      &:last-child {
        border-right: 0.25px solid var(--color-table-border-color);
      }

      &:first-child {
        border-left: 0.25px solid var(--color-table-border-color);
      }
    }

    &:last-child {
      .mat-mdc-cell {
        &:last-child {
          border-bottom-right-radius: 0.6rem;
        }

        &:first-child {
          border-bottom-left-radius: 0.6rem;
        }
      }

    }
  }

  .mat-mdc-row {
    .element-hover {
      display: none;
    }

    &:hover {
      background: var(--color-table-hover);

      .element-hover {
        display: block;
      }
    }
  }
}
