.mat-mdc-menu-panel {
  background: transparent;

  .mat-mdc-menu-content {
    border-radius: 0.6rem;
    background: var(--color-blue);
    padding: 0;

    .mat-mdc-menu-item {
      .mat-mdc-menu-item-text {
        min-width: 8.5rem;
        font-weight: var(--fw-normal);
        font-size: 1.2rem;
        color: inherit;
      }

      &:hover {
        color: var(--color-blue);
        background: var(--color-white);
        cursor: pointer;
      }

      &.cdk-focused {
        color: var(--color-blue);
        background: var(--color-white);
      }
    }

    .mat-icon {
      color: inherit;
    }
  }

  .mat-mdc-menu-item {
    --mat-menu-item-label-text-color: var(--color-header-text);
    padding: 0 1rem;
    min-height: 1.5rem;

    .mat-icon {
      --mat-menu-item-icon-size: 1.4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 1rem;
      min-width: var(--mat-menu-item-icon-size);
    }

    span.mat-mdc-menu-item-text {
      min-width: 8.5rem;
      font-weight: var(--fw-normal);
      font-size: 1.2rem;
      color: inherit;
    }
  }

  &.sections-tree-menu {
    --mat-menu-container-shape: 0.6rem;
    --mat-menu-container-color: var(--color-tree-menu-bg);
    box-shadow: 1px 1px 4px 0 #1e323b1a;

    .mat-mdc-menu-content {
      border-radius: unset;
      background: unset;
      padding: 0;

      .mat-mdc-menu-item {
        --mat-menu-item-hover-state-layer-color: var(--color-tree-menu-hover);
        --mat-menu-item-label-text-color: var(--color-tree-menu);
        display: flex;
        align-items: center;
        gap: 0.8rem;
        padding: var(--s-2) var(--s-2);

        &.cdk-focused {
          background: var(--color-tree-menu-hover);
        }
      }

      .mat-mdc-menu-item-text {
        line-height: 1.8rem;
      }

      .mat-icon {
        color: var(--mat-menu-item-label-text-color);
        margin: 0;
      }
    }
  }
}
