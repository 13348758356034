@use "sass:map";
@use "@angular/material" as mat;
@use "material/modules/button-theme" as button;
@use "material/modules/form-field-theme" as form-field;
@use "material/modules/icon-theme" as icon;

@use "material/palettes/light-theme" as light-theme;
@use "material/palettes/dark-theme" as dark-theme;

@include mat.core();

@mixin custom-material-theme($custom-theme) {
  @include button.theme($custom-theme);
  @include form-field.theme($custom-theme);
  @include icon.theme($custom-theme);
}

@include mat.all-component-themes(light-theme.$light-theme);
@include custom-material-theme(light-theme.$light-theme);

.theme-dark {
  @include mat.all-component-colors(dark-theme.$dark-theme);
  @include custom-material-theme(dark-theme.$dark-theme);
}
