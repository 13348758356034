.spacer {
  flex: 1 0 auto;
}

.content-container-auth {
  color: var(--color-white);

  flex: 1;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin: 3rem auto;

  &__btn {
    background: transparent;
    position: absolute;
    border: none;
    outline: none;
    padding: 0;
    color: inherit;
    cursor: pointer;
    top: 2rem;
    left: 2rem;
  }

  &__card {
    position: relative;
    padding: 8rem 10rem 13.4rem;
    background: linear-gradient(129.21deg, #6b9399 16.91%, #375e6a 76.16%);
    border-radius: 0.6rem;
    max-width: 58.4rem;
  }
}

.container {
  padding-left: 8%;
  padding-right: 8%;
}

.mat-mdc-tooltip.mdc-tooltip--shown.mdc-tooltip {
  .mdc-tooltip__surface {
    font-size: 1rem;
  }
}

.bold {
  font-weight: var(--fw-bold);
}

