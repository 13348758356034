@import 'variables/scss-variables';

.grid-rows-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr));
}
@media (min-width: $tablet-s) {
  .md\:grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr));
  }
}
@media (min-width: $tablet-s) {
  .xl\:grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr));
  }
}
