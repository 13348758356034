@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $primary-color: mat.m2-get-color-from-palette($primary-palette, 500);
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);
  $font-family: mat.m2-font-family($typography-config);
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);

  @if $typography-config != null {
    @include typography($theme);
  }
}

.mat-mdc-form-field {
  width: 100%;
  position: relative;
  --mdc-shape-small: var(--s-1_5);
  --mdc-outlined-text-field-container-shape: var(--s-1_5);

  &:not(.mat-form-field-disabled) {
    .mat-mdc-floating-label {
      &.mdc-floating-label {
        cursor: text !important;
      }
    }
  }

  // TODO: switch !importants to --mdc variables
  .mdc-text-field--invalid:not(.mdc-text-field--disabled) {
    .mdc-notched-outline__leading {
      border-color: var(--color-red-4) !important;
    }

    .mdc-notched-outline__notch {
      border-color: var(--color-red-4) !important;
    }

    .mdc-notched-outline__trailing {
      border-color: var(--color-red-4) !important;
    }

    .mat-mdc-floating-label {
      color: var(--color-red-4) !important;
    }
  }

  .mat-mdc-form-field-flex {
    .mdc-notched-outline__leading {
      width: var(--mdc-outlined-text-field-container-shape) !important;
      border-width: 0.25px !important;
    }

    .mdc-notched-outline__notch {
      border-width: 0.25px !important;
    }

    .mdc-notched-outline__trailing {
      border-width: 0.25px !important;
    }
  }

  &.search {
    --mdc-shape-small: var(--s-2);
    --mdc-outlined-text-field-container-shape: var(--s-2);

    .mat-mdc-text-field-wrapper {
      .mdc-icon-button {
        color: var(--color-text);
        opacity: var(--opacity-60);

        .mat-mdc-button-persistent-ripple::before {
          background: transparent;
        }
      }

      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;
        }

        .mdc-notched-outline__leading {
          min-width: var(--s-5);
        }
      }
    }
  }

  .mat-mdc-form-field-flex {
    width: 100%;
  }

  &.input-fill .mdc-notched-outline {
    background: var(--color-input-fill-bg);
    border: 0.2px solid var(--color-input-fill-border);
    border-radius: var(--s-1_5);

    .mdc-notched-outline__leading,
    .mdc-notched-outline__trailing,
    .mdc-notched-outline__notch {
      border-color: var(--color-input-border);
      border-width: 0.2px !important;
    }
  }

  &.chart-input {
    background: rgba(var(--value-teal-2) / 10%);
    color: var(--color-card-text);
    border-radius: var(--s-1_5);

    .mat-mdc-text-field-wrapper {
      .mat-mdc-form-field-infix {
        padding: 0.225rem 0 !important;
      }

      .mat-mdc-select-arrow-wrapper .mat-mdc-select-arrow {
        color: var(--color-blue) !important;
      }

      .mat-mdc-select-value {
        color: var(--color-card-text) !important;
      }

      .mdc-notched-outline__leading,
      .mdc-notched-outline__trailing,
      .mdc-notched-outline__notch {
        border-color: transparent;
      }
    }
  }

  &.mat-form-field-invalid .mat-mdc-form-field-subscript-wrapper {
    z-index: 2;
  }

  .mat-mdc-form-field-subscript-wrapper {
    z-index: -1;
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0;
    transform: translateY(100%);

    .mat-mdc-form-field-error-wrapper {
      padding: var(--s-1_5) var(--s-2);
      background: var(--color-red-4);
      box-shadow: 0 0.2rem 0.4rem rgb(var(--value-blue) / 0.2);
      border-radius: var(--s-1_5);
      line-height: 1.4rem;
      color: white;

      .error-icon {
        color: var(--color-red-4);
        width: var(--s-3_5);
        height: var(--s-3_5);
        margin-right: var(--s-2_5);
      }

      .mat-mdc-form-field-error {
        display: flex;
        align-items: center;
        color: var(--color-white);
      }
    }
  }

  .mat-mdc-text-field-wrapper {
    padding-left: var(--s-2);
    padding-right: var(--s-2);

    &.mdc-text-field {
      &--outlined {
        .mat-mdc-form-field-infix {
          padding-top: 0.725rem;
          padding-bottom: 0.725rem;
          min-height: auto;
        }
      }
    }

    .mat-mdc-input-element {
      font-size: 1.2rem;
      font-weight: var(--fw-normal);
    }
  }

  /*  label.mdc-floating-label.mat-mdc-floating-label {
      top: 2rem;

      &.mdc-floating-label--float-above {
        top: 2.6rem;
      }
    }*/

  label.mdc-floating-label.mat-mdc-floating-label {
    font-size: 1.2rem;
    font-weight: var(--fw-normal);
    left: 0.3rem;
    top: var(--s-5);

    /* stylelint-disable */
    &.mdc-floating-label--float-above {
      font-size: 1.1rem;
      left: var(--s-1);
      top: 2.7rem;
    }
  }

  .mat-mdc-icon-button.mat-mdc-button-base {
    width: var(--s-10);
    height: var(--s-10);
    display: flex;
    justify-content: center;
    align-items: center;

    .mat-icon {
      width: auto;
      height: auto;
      font-size: 1.6rem;
    }
  }

  &.custom-field {
    --mdc-outlined-text-field-container-shape: var(--s-2);

    .mat-mdc-form-field-flex .mat-mdc-select .mat-mdc-select-value-text,
    .mat-mdc-select-placeholder {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 2.7rem;
    }
  }

  &.base-form-field {
    --mdc-outlined-text-field-container-shape: var(--s-2);

    .mat-mdc-form-field-flex .mat-mdc-select .mat-mdc-select-value-text,
    .mat-mdc-select-placeholder,
    .mat-mdc-input-element,
    .mdc-floating-label.mat-mdc-floating-label {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 2.1rem;
    }
  }
}

.cdk-overlay-pane {
  .mat-mdc-select-panel {
    &.default-panel {
      padding: 0;

      .mat-mdc-option {
        min-height: 5.3rem;
      }
    }

    &.custom-panel {
      --mat-select-container-elevation-shadow: 0 0.1rem 0.4rem var(--color-blue-05);
      --mat-option-label-text-size: 1.4rem;
      //--mat-option-selected-state-layer-color: var(--color-teal);
      padding: 0;
      border-radius: var(--s-2);
      border-style: solid;
      border-color: var(--mdc-outlined-text-field-focus-outline-color);
      border-width: 1px;
      margin-top: -1px;

      .mat-mdc-option {
        min-height: 4.2rem;

        mat-pseudo-checkbox {
          display: none;
        }
      }
    }
  }
}

.form__white {
  .mat-mdc-form-field:not(.mat-form-field-invalid) {
    .mdc-notched-outline__leading {
      border-color: var(--color-white) !important;
    }

    .mdc-notched-outline__notch {
      border-color: var(--color-white) !important;

      .mdc-floating-label {
        --mdc-outlined-text-field-hover-label-text-color: var(--color-white);
        color: var(--color-white);
      }
    }

    .mdc-notched-outline__trailing {
      border-color: var(--color-white) !important;
    }
  }

  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
    color: var(--color-white);
    caret-color: var(--color-white);
  }
}
