@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $primary-color: mat.m2-get-color-from-palette($primary-palette, 500);
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);
  $font-family: mat.m2-font-family($typography-config);
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);

  @if $typography-config != null {
    @include typography($theme);
  }

  mat-icon.mat-icon {
    $initialSize: 2.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: var(--icon-size, $initialSize);
    width: var(--icon-size, $initialSize);
    height: var(--icon-size, $initialSize);
  }

  .theme-light {
  }

  .theme-dark {
  }
}
