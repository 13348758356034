@import 'variables/scss-variables';

.grid-flow-row-dense {
  grid-auto-flow: row dense;
}
@media (min-width: $desktop-s) {
  .xl\:grid-flow-col-dense {
    grid-auto-flow: column dense;
  }
}
