// Flexbox direction
.flex-col {
  flex-direction: column;
}

// Flexbox justify content
.justify-center {
  justify-content: center;
}
.justify-between {
  justify-content: space-between;
}

// Flexbox align items
.items-center {
  align-items: center;
}
