// Width
.w-full {
  width: 100%;
}

.w-8 {
  width: var(--s-8);
}

// Max width
.max-w-module-content {
  max-width: var(--module-content-width);
}
