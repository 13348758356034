* {
  --mozilla-scrollbar-colors: rgb(var(--value-black) / 50%) transparent;
  scrollbar-color: var(--mozilla-scrollbar-colors);
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: transparent;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: rgb(var(--value-black) / 50%);

    &:hover {
      background-color: rgb(var(--value-black) / 70%);
    }
  }

  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}
