@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
  $color-config: mat.m2-get-color-config($theme);
  $primary-palette: map.get($color-config, 'primary');
  $primary-color: mat.m2-get-color-from-palette($primary-palette, 500);
}

@mixin typography($theme) {
  $typography-config: mat.m2-get-typography-config($theme);
  $font-family: mat.m2-font-family($typography-config);
}

@mixin theme($theme) {
  $color-config: mat.m2-get-color-config($theme);

  @if $color-config != null {
    @include color($theme);
  }

  $typography-config: mat.m2-get-typography-config($theme);

  @if $typography-config != null {
    @include typography($theme);
  }
}

button,
a {
  --btn-font-weight: var(--fw-bold);

  &.mat-mdc-button-base:not(.mdc-icon-button) {
    border-radius: 6px;
  }

  &.mdc-button,
  &.mdc-icon-button {
    overflow: hidden;
    letter-spacing: initial;
  }

  &.mat-mdc-raised-button {
    &:disabled {
      opacity: var(--opacity-70);
    }

    .mdc-button__label {
      font-weight: var(--fw-bold);
      font-size: 1.4rem;
    }
  }

  &.mat-mdc-button-base[mat-flat-button] {
    &:disabled {
      opacity: var(--opacity-50);
    }

    .mdc-button__label {
      font-weight: var(--btn-font-weight);
      font-size: 1.4rem;
      z-index: unset;
    }
  }

  &.mat-mdc-icon-button.mat-mdc-button-base {
    --btn-size: var(--s-12);
    --icon-size: var(--s-6);
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--btn-size);
    height: var(--btn-size);

    .mat-icon {
      --mdc-icon-button-icon-size: var(--icon-size);
      display: flex;
      align-items: center;
      justify-content: center;
      width: var(--icon-size);
      min-width: var(--icon-size);
      height: var(--icon-size);
    }
  }
}

.theme-light {
  button.mat-mdc-button-base,
  a.mat-mdc-button-base {
    &.mat-primary {
      background: var(--color-blue);
      color: var(--color-white);
    }

    &.mat-accent[mat-flat-button] {
      background: transparent;
      color: var(--color-text);
      border: 0.25px solid var(--color-text);
    }

    &_inverted {
      &.mat-primary {
        background: var(--color-white);
        color: var(--color-blue);
      }
    }
  }

  button.mat-mdc-raised-button,
  a.mat-mdc-raised-button {
    &.mat-primary {
      background: var(--color-blue);
      color: var(--color-white);
    }

    &.mat-accent {
      background: var(--color-white);
      color: var(--color-blue);
    }
  }
}

.theme-dark {
  button.mat-mdc-button-base,
  a.mat-mdc-button-base {
    &.mat-accent[mat-flat-button] {
      background: var(--color-input-bg);
      color: var(--color-table-text);
      border: 0.25px solid var(--color-white);
    }

    &.mat-primary[mat-flat-button] {
      background: var(--color-white);
      color: var(--color-blue);
    }

    &_inverted {
      &.mat-primary[mat-flat-button] {
        background: var(--color-blue);
        color: var(--color-white);
      }
    }
  }

  button.mat-mdc-raised-button,
  a.mat-mdc-raised-button {
    &.mat-primary {
      background: var(--color-blue);
      color: var(--color-white);
    }

    &.mat-accent {
      background: var(--color-white);
      color: var(--color-blue);
    }
  }
}
