:root {
  --opacity-0: 0;
  --opacity-30: 0.3;
  --opacity-50: 0.5;
  --opacity-60: 0.6;
  --opacity-70: 0.7;
  --opacity-75: 0.75;

  --add-button-hide-opacity: var(--opacity-0);
  --drag-and-drop-placeholder-opacity: var(--opacity-0);
}
