.gap-2 {
  gap: var(--s-2);
}
.gap-2_5 {
  gap: var(--s-2_5);
}
.gap-3 {
  gap: var(--s-3);
}
.gap-5 {
  gap: var(--s-5);
}
