.mat-mdc-slide-toggle {
  &_blue {
    .mdc-switch {
      &.mdc-switch--checked {
        .mdc-switch__track {
          background: var(--color-blue);
        }
      }

      .mdc-switch__track {
        background: rgba(var(--value-blue) / 50%);
      }
    }
  }

  .mdc-switch {
    .mdc-switch__track {
      height: 20px !important;
    }

    &.mdc-switch--checked {
      .mdc-switch__handle {
        left: -3px;
      }
    }

    .mdc-switch__handle {
      transition: left 50ms;
      left: 3px;
    }

    .mdc-switch__shadow {
      background: white;
    }

    &:enabled .mdc-switch__track::after,
    &:enabled .mdc-switch__track::before {
      background: var(--color-slide-bg) !important;
    }
  }

  .mdc-switch__icons {
    display: none;
  }

  .mdc-switch__handle {
    width: 14px !important;
    height: 14px !important;
  }

  .mdc-switch__track {
    border-radius: 100px !important;
  }

  .mdc-switch__handle-track {
    width: 14px !important;
  }

  /* stylelint-disable-next-line */
  .mdc-switch--selected .mdc-switch__handle-track {
    transform: translateX(148%);
  }

  .mdc-switch__ripple {
    display: none;
  }
}
