@import "variables/scss-variables";

.auth-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__row {
    margin-bottom: 4rem;
  }

  &__wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: 74rem;
    width: 100%;
    column-gap: 1.8rem;
  }

  &__go-to-link {
    margin: 2.5rem 0;
    font-size: 2rem;
  }

  &__heading {
    text-align: center;
  }

  &__row,
  &__field,
  &__button {
    display: flex;
    max-width: 36rem;
    width: 100%;
  }

  &__field {
    > * {
      width: 100%;
    }
  }
}
