.mat-accordion {
  &_content {
    &.mat-accordion_description {
      .mat-expansion-panel {
        .mat-expansion-panel-header {
          padding: 0 1.4rem 2rem;
          height: 5rem;

          .mat-content {
            height: 3rem;
          }

          .mat-expansion-indicator {
            width: 1.22rem;
            height: 1.22rem;
            margin-left: 0.5rem;

            &::after {
              top: auto;
              padding: 0.4rem;
            }
          }
        }

        .mat-expansion-panel-header-title {
          padding: 0;
          font-weight: var(--fw-normal);
          font-size: 1.2rem;
          line-height: normal;
        }

        .mat-expansion-panel-body {
          font-size: 1.2rem;
          padding: 0.5rem 1.4rem 0;
        }
      }
    }

    &.mat-accordion_activity {
      .mat-expansion-panel {
        margin-bottom: 2rem;

        .mat-expansion-panel-header {
          padding: 0 1.4rem;
          height: auto;

          .mat-content {
            height: auto;
          }

          .mat-expansion-indicator {
            width: 1.22rem;
            height: 1.22rem;
            margin-left: -2.5rem;

            &::after {
              top: auto;
              padding: 0.4rem;
            }
          }
        }

        .mat-expansion-panel-header-title {
          padding: 0;
          font-weight: var(--fw-normal);
          font-size: 1.2rem;
          line-height: normal;
        }

        .mat-expansion-panel-body {
          font-size: 1.2rem;
          padding: 0.5rem 1.4rem 0;
        }
      }
    }

    .mat-expansion-panel {
      box-shadow: none !important;
      background: transparent;
      color: var(--color-dialog-text-color);
      margin-top: 0;
      margin-bottom: 1.5rem;

      &:last-child {
        margin-bottom: 0;
      }

      .mat-expansion-panel-header {
        position: relative;
        padding: 0;
        height: 3rem;

        &_white {
          .mat-expansion-panel-header-title {
            color: var(--color-white);
          }

          .mat-expansion-indicator::after {
            color: var(--color-white);
          }
        }
      }

      .mat-content {
        flex: initial;
        height: 7rem;
        overflow: visible;

        .mat-expansion-panel-header-title {
          color: var(--color-dialog-text-color);
          margin-right: 0.5rem;
        }
      }

      .mat-expansion-panel-header-description {
        position: absolute;
        width: 100%;
        bottom: 0;
      }

      .mat-expansion-indicator {
        height: 8px;

        &::after {
          color: var(--color-dialog-text-color);
          position: relative;
          top: -6px;
        }
      }

      .mat-expansion-panel-body {
        padding: 1rem 0 0;
      }
    }
  }
}
