@import 'variables/scss-variables';

// Padding
.p-3 {
  padding: var(--s-3);
}
@media (min-width: $tablet-s) {
  .md\:p-10 {
    padding: var(--s-10);
  }
}

// Padding Left
.pl-2_5 {
  padding-left: var(--s-2_5);
}
.pl-20 {
  padding-left: var(--s-20);
}

// Padding Right
.pr-30 {
  padding-right: var(--s-30);
}

// Padding Horizontal
.px-3 {
  padding-left: var(--s-4);
  padding-right: var(--s-4);
}
.px-4 {
  padding-left: var(--s-4);
  padding-right: var(--s-4);
}
.px-5 {
  padding-left: var(--s-5);
  padding-right: var(--s-5);
}
.px-6 {
  padding-left: var(--s-6);
  padding-right: var(--s-6);
}
.px-10 {
  padding-left: var(--s-10);
  padding-right: var(--s-10);
}
.px-20 {
  padding-left: var(--s-20);
  padding-right: var(--s-20);
}
@media (min-width: $tablet-s) {
  .md\:px-10 {
    padding-left: var(--s-10);
    padding-right: var(--s-10);
  }
  .md\:px-20 {
    padding-left: var(--s-20);
    padding-right: var(--s-20);
  }
}

// Padding Vertical
.py-1_5 {
  padding-top: var(--s-1_5);
  padding-bottom: var(--s-1_5);
}
.py-2 {
  padding-top: var(--s-2);
  padding-bottom: var(--s-2);
}
.py-4 {
  padding-top: var(--s-4);
  padding-bottom: var(--s-4);
}
.py-5 {
  padding-top: var(--s-5);
  padding-bottom: var(--s-5);
}
.py-10 {
  padding-top: var(--s-10);
  padding-bottom: var(--s-10);
}

@media (min-width: $tablet-s) {
  .md\:py-10 {
    padding-top: var(--s-10);
    padding-bottom: var(--s-10);
  }
}
