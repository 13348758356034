// SIZES
:root {
  --s-1: 0.4rem; // 4px
  --s-1_5: 0.6rem; // 6px
  --s-2: 0.8rem; // 8px
  --s-2_5: 1rem; // 10px
  --s-3: 1.2rem; // 12px
  --s-3_5: 1.4rem; // 14px
  --s-4: 1.6rem; // 16px
  --s-4_5: 1.8rem; // 18px
  --s-5: 2rem; // 20px
  --s-6: 2.4rem; // 24px
  --s-7: 2.8rem; // 28px
  --s-7_5: 3rem; // 30px
  --s-8: 3.2rem; // 32px
  --s-9: 3.6rem; // 36px
  --s-10: 4rem; // 40px
  --s-11: 4.4rem; // 44px
  --s-12: 4.8rem; // 48px
  --s-12_5: 5rem; // 50px
  --s-13: 5.2rem; // 52px
  --s-14: 5.6rem; // 56px
  --s-15: 6rem; // 60px
  --s-16: 6.4rem; // 64px
  --s-17: 6.8rem; // 68px
  --s-18: 7.2rem; // 72px
  --s-19: 7.6rem; // 76px
  --s-20: 8rem; // 80px
  --s-25: 10rem; // 100px
  --s-30: 12rem; // 120px

  --content-width: 150rem;
  --modal-top-margin: 12rem;
  --modal-side-padding: 4rem;
  --footer-height: 4rem;
  --module-content-width: 128rem;
}
