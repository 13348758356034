:root {
  // VALUES

  --value-black: 0 0 0; // #000
  --value-white: 255 255 255; // #fff
  --value-red: 242 9 23; // #f20917
  --value-red-2: 163 83 83; // #A35353
  --value-red-3: 244 67 54; // #f44336
  --value-red-4: 254 147 140; // #FE938C
  --value-gradient-start: 107 147 153; // #6B9399FF
  --value-gradient-end: 55 94 106; // #375E6AFF
  --value-blue: 30 50 59; // #1E323B
  --value-teal: 136 168 173; // #88A8AD
  --value-teal-2: 110 140 147; // #6E8C93FF
  --value-teal-3: 62 85 93; // #3e555d
  --value-teal-4: 30 50 5; // #1E3205FF
  --value-light-teal: 244 246 247; // #F4F6F7
  --value-barley-white: 255, 244, 201; // #FFF4C9

  // COLORS
  --color-black: rgb(var(--value-black)); // #000
  --color-white: rgb(var(--value-white)); // #fff
  --color-red: rgb(var(--value-red)); // #f20917
  --color-red-2: rgb(var(--value-red-2)); // #A35353
  --color-red-3: rgb(var(--value-red-3)); // #f44336
  --color-red-4: rgb(var(--value-red-4)); // #FE938C
  --color-red-5: rgba(254, 147, 140, 0.2); // #FE938C 20%
  --color-red-6: #FBF1F0;
  --color-gradient-start: rgb(var(--value-gradient-start)); // #6B9399
  --color-gradient-end: rgb(var(--value-gradient-end)); // #375E6A
  --color-gradient: linear-gradient(129.21deg, rgb(var(--value-gradient-start)) 16.91%, rgb(var(--value-gradient-end)) 76.16%);
  --color-blue: rgb(var(--value-blue));
  --color-blue-05: rgb(var(--value-blue) / 5%);
  --color-blue-10: rgb(var(--value-blue) / 10%);
  --color-blue-25: rgb(var(--value-blue) / 25%);
  --color-teal: rgb(var(--value-teal)); // #88A8AD
  --color-teal-2: rgb(var(--value-teal-2)); // #6E8C93FF
  --color-teal-4: rgb(var(--value-teal-4)); // #1E3205FF
  --color-teal-6: #B8CBCE; // #1E3205FF
  --color-teal-30: rgb(var(--value-teal) / 30%);
  --color-light-teal: rgb(var(--value-light-teal)); // #F4F6F7
  --color-barley-white: rgb(var(--value-barley-white)); // #FFF4C9
}
