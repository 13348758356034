// Margin Top
.mt-0 {
  margin-top: 0;
}
.mt-1 {
  margin-top: var(--s-1);
}
.mt-2 {
  margin-top: var(--s-2);
}
.mt-3 {
  margin-top: var(--s-3);
}
.mt-4 {
  margin-top: var(--s-4);
}
.mt-5 {
  margin-top: var(--s-5);
}
.mt-8 {
  margin-top: var(--s-8);
}
.mt-10 {
  margin-top: var(--s-10);
}
.mt-15 {
  margin-top: var(--s-15);
}

// Margin Bottom
.mb-0 {
  margin-bottom: 0;
}
.mb-1 {
  margin-bottom: var(--s-1);
}
.mb-2 {
  margin-bottom: var(--s-2);
}
.mb-3 {
  margin-bottom: var(--s-3);
}
.mb-4 {
  margin-bottom: var(--s-4);
}
.mb-5 {
  margin-bottom: var(--s-5);
}
.mb-7_5 {
  margin-bottom: var(--s-7_5);
}
.mb-8 {
  margin-bottom: var(--s-8);
}

// Margin Left
.ml-10 {
  margin-left: var(--s-10);
}

// Margin Horizontal
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
