image-cropper.image-cropper {
    .ngx-ic-cropper {
      &::after {
        box-shadow: 0 0 0 100vw rgb(var(--value-blue) / 0.5) !important;
      }

      .ngx-ic-move {
        border: none !important;
      }

      border: 0.5px dashed var(--color-black);

      span.ngx-ic-resize.ngx-ic-top, span.ngx-ic-resize.ngx-ic-right, span.ngx-ic-resize.ngx-ic-bottom, span.ngx-ic-resize.ngx-ic-left {
        display: none !important;
      }

      .ngx-ic-resize.ngx-ic-topleft,
      .ngx-ic-resize.ngx-ic-topright,
      .ngx-ic-resize.ngx-ic-bottomleft,
      .ngx-ic-resize.ngx-ic-bottomright {
        padding: 0 !important;

        .ngx-ic-square {
          display: none !important;
        }
      }

      .ngx-ic-resize.ngx-ic-topleft {
        top: -1.5px !important;
        left: -1.5px !important;
        width: 1.4rem !important;
        height: 1.4rem !important;

        border-left: 3px solid var(--color-white);
        border-top: 3px solid var(--color-white);
      }

      .ngx-ic-resize.ngx-ic-topright {
        top: -1.5px !important;
        right: -1.5px !important;
        width: 1.4rem !important;
        height: 1.4rem !important;

        border-right: 3px solid var(--color-white);
        border-top: 3px solid var(--color-white);
      }

      .ngx-ic-resize.ngx-ic-bottomleft {
        bottom: -1.5px !important;
        left: -1.5px !important;
        width: 1.4rem !important;
        height: 1.4rem !important;

        border-left: 3px solid var(--color-white);
        border-bottom: 3px solid var(--color-white);
      }

      .ngx-ic-resize.ngx-ic-bottomright {
        bottom: -1.5px !important;
        right: -1.5px !important;
        width: 1.4rem !important;
        height: 1.4rem !important;

        border-right: 3px solid var(--color-white);
        border-bottom: 3px solid var(--color-white);
      }
    }

  &.image-cropper_edit {
    .ngx-ic-cropper {
      &::after {
        box-shadow: none!important;
        border: none!important;
      }
    }
  }
}
