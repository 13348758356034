@use '@angular/material' as mat;

$color: #fafafa;

$typography: mat.m2-define-typography-config(
  $font-family: var(--ff-main-font)
);

html.theme-dark:root {
  --value-header-bg: var(--value-white);
  --value-table-border-color: var(--value-white);
  --color-primary: $color;
  --color-primary-05: rgba($color, 0.05);
  --color-header-bg: rgb(var(--value-header-bg));
  --color-header-text: var(--color-blue);
  --color-header-logo: var(--color-teal);
  --color-bg: var(--color-blue);
  --color-text: var(--color-white);
  --color-table-header-bg: var(--color-teal);
  --color-table-header-text: var(--color-white);
  --color-table-bg: rgba(var(--value-teal-2) / 10%);
  --color-table-text: var(--color-white);
  --color-table-hover: rgba(var(--value-teal-2) / 25%);
  --color-table-hover-color: inherit;
  --color-table-button: rgba(var(--value-teal-2) / 40%);
  --color-table-border-color: rgba(var(--value-table-border-color) / 50%);
  --color-dialog-bg: var(--color-white);
  --color-dialog-header-color: var(--color-blue);
  --color-dialog-massage-color: var(--color-teal);
  --color-dialog-text-color: var(--color-blue);
  --color-accent-header: var(--color-white);
  --color-card-bg: var(--color-white);
  --color-card-text: var(--color-blue);
  --color-slide-bg: rgba(var(--value-teal-2) / 10%);
  --color-input-bg: rgba(var(--value-teal-2) / 10%);
  --color-input-border: transparent;
  --color-badge-bg: var(--color-white);
  --color-badge-text: var(--color-blue);

  --color-icon-btn-bg: var(--color-blue);
  --color-icon-btn-bg-hover: var(--color-white);
  --color-icon-btn-color: var(--color-white);
  --color-icon-btn-color-hover: var(--color-blue);
  --color-icon-btn-border: var(--color-white);
  --color-icon-btn-border-hover: var(--color-white);

  --color-input-fill-bg: rgba(136, 168, 173, 0.3);
  --color-input-fill-border: rgba(var(--value-white) / 20%);
  --color-input-profile-color: rgba(var(--value-white) / 60%);
  --color-input-profile-border: rgba(var(--value-white) / 50%);

  --color-paginator-current-bg: rgba(var(--value-teal-2) / 10%);
  --color-block-hover: rgba(136, 168, 173, 0.3);
  --color-table-badge-bg: rgba(136, 168, 173, 0.3);

  --color-slider-track: rgb(var(--value-blue) / 10%);
  --color-slider-thumb: var(--color-teal);
  --color-slider-bullet: var(--color-white);
  --color-slider-bullet-border: var(--color-teal);
  --color-slider-bullet-border-selected: var(--color-blue);
  --color-slider-bullet-border-disabled: rgb(var(--value-blue) / 10%);
  --color-slider-thumb-readonly: var(--color-teal-6);
  --color-slider-bullet-readonly: var(--color-teal-6);
  --color-slider-bullet-selected-readonly: var(--color-blue);
  --color-slider-bullet-disabled-readonly: #E8EAEB;

  --color-playbook-nav-bar: var(--color-blue);
  --color-playbook-highlight: var(--color-teal-30);
  --color-playbook-card-description: var(--color-gradient-end);

  --color-tree-menu: var(--color-blue);
  --color-tree-menu-bg: var(--color-white);
  --color-tree-menu-hover: rgb(var(--value-teal) / 30%);

  --color-blocks-search-input: var(--color-white);
}

$palette-dark: (
  50:
    lighten(
      $color: $color,
      $amount: 0.5
    ),
  100:
    lighten(
      $color: $color,
      $amount: 0.25
    ),
  200: $color,
  300:
    darken(
      $color: $color,
      $amount: 0.1
    ),
  400:
    darken(
      $color: $color,
      $amount: 0.2
    ),
  500:
    darken(
      $color: $color,
      $amount: 0.3
    ),
  600:
    darken(
      $color: $color,
      $amount: 0.4
    ),
  700:
    darken(
      $color: $color,
      $amount: 0.5
    ),
  800:
    darken(
      $color: $color,
      $amount: 0.6
    ),
  900:
    darken(
      $color: $color,
      $amount: 0.7
    ),
  A100:
    lighten(
      $color: $color,
      $amount: 0.2
    ),
  A200:
    darken(
      $color: $color,
      $amount: 0.05
    ),
  A400:
    darken(
      $color: $color,
      $amount: 0.25
    ),
  A700:
    darken(
      $color: $color,
      $amount: 0.55
    ),
  contrast: (
    50: var(--color-white),
    100: var(--color-white),
    200: var(--color-white),
    300: var(--color-white),
    400: var(--color-white),
    500: var(--color-white),
    600: var(--color-white),
    700: var(--color-white),
    800: var(--color-white),
    900: var(--color-white),
    A100: var(--color-white),
    A200: var(--color-white),
    A400: var(--color-white),
    A700: var(--color-white)
  )
);

$dark-theme: mat.m2-define-dark-theme(
  (
    color: (
      primary: mat.m2-define-palette($palette-dark),
      accent: mat.m2-define-palette(mat.$m2-blue-grey-palette)
    ),
    typography: $typography
  )
);
