.ngx-toastr {
  box-shadow: none!important;
  padding: 8px 17px 8px 8px!important;
  border-radius: 6px!important;
  width: 384px!important;
  background-image: none!important;

  &.toast-error {
    background-color: var(--color-red-4);
  }

  &.toast-success {
    background-color: var(--color-teal);
  }

  &.toast-info {
    background-color: var(--color-blue);
  }
}

